.fb_transport_ {
  &airbus:before {
    content: "\f100";
  }
  &airplane:before {
    content: "\f101";
  }
  &airplane_old:before {
    content: "\f102";
  }
  &airport:before {
    content: "\f103";
  }
  &boat:before {
    content: "\f104";
  }
  &bus:before {
    content: "\f105";
  }
  &car:before {
    content: "\f106";
  }
  &car_rental:before {
    content: "\f107";
  }
  &ferry_boat:before {
    content: "\f108";
  }
  &garage:before {
    content: "\f109";
  }
  &helicopter:before {
    content: "\f10a";
  }
  &heliport:before {
    content: "\f10b";
  }
  &hovercraft:before {
    content: "\f10c";
  }
  &metro:before {
    content: "\f10d";
  }
  &motorbike:before {
    content: "\f10e";
  }
  &outdoor_parking:before {
    content: "\f10f";
  }
  &parking:before {
    content: "\f110";
  }
  &roulotte:before {
    content: "\f111";
  }
  &seal_boat:before {
    content: "\f112";
  }
  &shuttle_bus:before {
    content: "\f113";
  }
  &taxi:before {
    content: "\f114";
  }
  &train:before {
    content: "\f115";
  }
  &tram:before {
    content: "\f116";
  }
  &yacht_mooring:before {
    content: "\f117";
  }
  &car_2:before {
    content: "\f118";
  }
  &locomotive:before {
    content: "\f119";
  }
  &train_station:before {
    content: "\f120";
  }
  &ufo:before {
    content: "\f121";
  }
  &wagon:before {
    content: "\f122";
  }
  &rocket:before {
    content: "\f123";
  }
  &rocket_2:before {
    content: "\f124";
  }
  &offroad_vehicle:before {
    content: "\f125";
  }
  &quad:before {
    content: "\f126";
  }
  &airplane_old_2:before {
    content: "\f127";
  }
  &cruise:before {
    content: "\f128";
  }
  &rowboat:before {
    content: "\f129";
  }
  &sailing_ship:before {
    content: "\f12a";
  }
  &steamboat:before {
    content: "\f12b";
  }
  &travel:before {
    content: "\f12c";
  }
  &viking_ship:before {
    content: "\f12d";
  }
  &offroad_vehicle:before {
    content: "\f125";
  }
  &inflatable_boat:before {
    content: "\f12e";
  }
  &submarine:before {
    content: "\f12f";
  }
  &zeppelin:before {
    content: "\f131";
  }
  &gondola:before {
    content: "\f132";
  }
  &snowmobile:before {
    content: "\f133";
  }
}